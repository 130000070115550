<template>
 <div>
    <div class="right-year">
        <div class="right-year-lr">选择年份</div>
        <div class="block">
            <el-date-picker
                style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid #BAE0FF;"
                class="picker"
                :clearable="true"
                v-model="year"
                type="year"
                @change="yearChange"
                placeholder="选择年份">
                </el-date-picker>
        </div>
    </div>
    <div class="hot-list">
            <div class="hot-title">
                <div>排序</div>
                <div>地区</div>
                <div>总计</div>
                <div>发明授权</div>
                <div>实用新型</div>
                <div>外观设计</div>
            </div>
            <div class="list-detail" >
                <div v-for="(v,k) in tableData" :key="k" class="detail">
                    <div>
                        <div v-if="k+1==1" class="index1">{{k+1}}</div>
                        <div v-else-if="k+1==2" class="index2">{{k+1}}</div>
                        <div v-else-if="k+1==3" class="index3">{{k+1}}</div>
                        <div v-else-if="k+1>3" class="index4">{{k+1}}</div>
                    </div>
                    <div>{{v.city}}{{v.county}}</div>
                    <div class="hot-pr">
                        <div>{{v.allCount}}</div>
                        <div style="margin:35px 0 0 20px;"><el-progress :show-text="false" :percentage="v.rate" style="width:220px;" :stroke-width="6"></el-progress></div>
                    </div>
                    <div>{{v.famingShouquanCount}}</div>
                     <div>{{v.shiyongCount}}</div>
                      <div>{{v.waiguanCount}}</div>
                </div>
                <!-- <div style="clear:both;"></div> -->
        </div>  
    </div>
 </div>
</template>
<script>

export default {
  name: '',
  data(){
    return {
      year:'',
	  tableData:[],
      province:"",
      city:"",
      county:"",
      level:""
    }
  },
  props:{
		
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	 yearChange(){
      this.getData()
    },
	 getData(){
         if (this.year == ""||this.year == null) {
		  this.year = ''
	  }else{
        this.year = new Date(this.year).getFullYear().toString()
      }
				this.$http.post(this.GLOBAL.new_url + '/patent/service/data/getValidAll',
					{
					"year":  this.year,
					"level": this.level,
                    "province": this.province,
                    "city": this.city,
                    "county":this.county
					}
            ).then((res) => {
                // console.log(res)
                this.tableData.length = 0
				this.tableData.push(...res.data.data)
				var _this = this;
            this.tableData.map((a, b) => {
              _this.$set(a, 'rate', a.waiguanCount/a.allCount*100);
            })
            // console.log(this.tableData)
              } 
			)		
	 },		   
  },
   mounted() {
     this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData();
    }
}
</script>

<style scoped>
.hot-list{
    width: 1500px;
    /* border:1px solid red; */
    margin-left: 40px;
}
.hot-title{
    height: 30px;
    font-size: 20px;
    color: #ffffff;
    line-height: 30px;
    margin-bottom: 10px;
}
.hot-title>div,.detail>div{
    float: left;
}
.hot-title>div:nth-of-type(1),.detail>div:nth-of-type(1){
    width: 150px;
}
.hot-title>div:nth-of-type(2),.detail>div:nth-of-type(2){
    width: 280px;
}
.hot-title>div:nth-of-type(3),.detail>div:nth-of-type(3){
    width: 500px;
}
.hot-title>div:nth-of-type(4),.detail>div:nth-of-type(4){
    width: 190px;
    text-align: center;
}
.hot-title>div:nth-of-type(5),.detail>div:nth-of-type(5){
    width: 190px;
    text-align: center;
}
.hot-title>div:nth-of-type(6),.detail>div:nth-of-type(6){
    width: 170px;
    text-align: center;
    /* border:1px solid red; */
}
:deep(.el-progress-bar__outer){
   background:#495969!important; 
 } 
 :deep(.el-progress-bar__inner) {
  background:linear-gradient(to right, #F2E625 ,  #2FEFD4 )
}
.list-detail{
    height: 570px;
    overflow-y: auto;
}
.detail{
    color: #FFFFFF;
   height: 80px;
   line-height: 80px;
   font-size: 18px;
}
.detail:hover{
      background:#264C8F!important; 
}
.detail>div:nth-of-type(1){
    line-height: normal;
}
.index1{
    width: 110px;
  height: 40px;
   line-height: 40px;
   padding-left: 10px;
  margin-top: 20px;
  background: url('../../../static/images/hot1.png') 100% no-repeat;
}
.index2{
    width: 110px;
  height: 40px;
   line-height: 40px;
   padding-left: 10px;
  margin-top: 20px;
  background: url('../../../static/images/hot2.png') 100% no-repeat;
}
.index3{
    width: 110px;
  height: 40px;
   line-height: 40px;
   padding-left: 10px;
  margin-top: 20px;
  background: url('../../../static/images/hot3.png') 100% no-repeat;
}
.index4{
    width: 110px;
  height: 40px;
   line-height: 40px;
   padding-left: 10px;
  margin-top: 20px;
  background: url('../../../static/images/hot4.png') 100% no-repeat;
}
.hot-pr>div{
    float: left;
}
.right-year{
   height: 40px;
   margin-bottom: 30px;
   margin-left: -20px;
}
.right-year>div{
   float: left;
   margin-left: 50px;
}
.right-year-lr{
   line-height: 40px;
   font-size: 16px;
   font-family: PingFang SC-Medium, PingFang SC;
   color: #BAE0FF;
}
</style>


