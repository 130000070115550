<template>
	<div class="main-container">
		<el-table :data="tableData" @row-click="handleTableRow" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
			<el-table-column prop="sqrmc" label="申请人" />
		    <el-table-column prop="xinxingCount" label="战略性新兴产业的有效发明专利" align="center" />
			<el-table-column prop="tenCount" label="维持年限超10年的有效发明专利" align="center" />
			<el-table-column prop="tongzuCount" label="在海外有同族专利权的有效发明专利" align="center"  />
            <el-table-column prop="zyCount" label="获得较高质押融资金额的有效发明专利" align="center"  />
            <el-table-column prop="jlCount" label="获得国家科技奖或中国专利奖的有效发明专利" align="center"  />
		</el-table>
		    <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1000px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
	</div>
</template>
<script>
export default {
  name: '',
  data(){
    return {
		province:"",
      city:"",
		county:"",
		level:"",
	
	  from:0,
	  total:0,
	  size:20,
	  tableData:[],
	  height:610,
    }
  },
  props:{
		
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
	 handleTableRow(row,column){
		this.$router.push({
			path:"/hingDetail",
			query:{
				company:row.sqrmc
			}
		})
	 },
	 getData(){	
			this.$http.post(this.GLOBAL.new_url + '/patent/service/data/getHighValue',
			{
			"year": '',
			"from": this.from,
			"size":this.size,
			"total": this.total,
			"level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county
			}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				this.tableData.map((x)=>{
					x.jlCount = Number(x.jlCount);
					x.tenCount = Number(x.tenCount);
					x.tongzuCount = Number(x.tongzuCount);
					x.xinxingCount = Number(x.xinxingCount);
					x.zyCount = Number(x.zyCount);
				})
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)		
	 },
	
useHeight(){
				return new Promise((res) => {
					this.$nextTick(() => {
						let heightCount = 0
						let mainEl = document.querySelector('.table-container' + this.index)
						heightCount = mainEl?.clientHeight
						heightCount = heightCount - 36 - 45
						this.height= heightCount
		                res(heightCount)
					})
				})				
			},    
  },
   mounted() {
	this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData();
	  this.useHeight()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
	cursor: pointer;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
:deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
.tab-searh :deep(.el-input__inner){
    padding-left: 14px!important;
    color: #FFFFFF!important;
	font-size: 16px;
}
.el-select-dropdown__item {
  font-size: 10px;
  color: #666666;
  font-weight: 500;
}
:deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
</style>


