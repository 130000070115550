<template>
	<div class="main-container">
		<el-table :data="tableData" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
		    <el-table-column prop="sbmc" label="公开号" />
			<el-table-column label="公开日">
				<template #default="scope">
					<el-image v-if="scope.row.imgUrl" :src="scope.row.imgUrl" 
					 style="width: 50px;height: 50px;" />
					 <!-- lazy -->
				</template>
			</el-table-column>
		    <el-table-column prop="intCls" label="申请号">
				<template v-slot="scope">
					<span>{{scope.row.intCls || scope.row.intcls}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="regId" label="申请日" >
				<template v-slot="scope">
					<span>{{scope.row.regId || scope.row.intregno}}</span>
				</template>
			</el-table-column>
		    <el-table-column prop="intregdate"  label="授权公告日" />
            <el-table-column prop="applicant" label="申请人">
				<template v-slot="scope">
					<span>{{scope.row.applicant || scope.row.sbsq}}</span>
				</template>
			</el-table-column>
		    <el-table-column prop="status" label="专利名称">
				
			</el-table-column>
		    <el-table-column prop="validDate" label="当前法律状态">
				<template v-slot="scope">
					<span style="color:#28C445" v-if="scope.row.validDate">{{scope.row.validDate.slice(0,10)}}</span>
				</template>
			</el-table-column>
		   
		</el-table>
		    <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1000px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
	</div>
</template>
<script>

export default {
  name: '',
  data(){
    return {
        from:0,
	  total:0,
	  size:20,
	  tableData:[],
	  height:550
    }
  },
  props:{
		
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
	 getData(){
		// console.log(this.index)
			// 	let url = ''
			// 	if (this.index == 0) {
			// 		url = 'getMadridData'
			// 	} else 
			// 	if (this.index == 1) {
			// 		url = 'renew'
			// 	} else if (this.index == 2) {
			// 		url = 'broaden'
			// 	}
			// 	this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/' + url,
			// 		{
			// 		"sbmc": '',
			// 		"applicant": this.applant,
			// 		"regId": '',
			// 		"intCls": '',
			// 		"from": this.from,
			// 		"size":this.size,
			// 		"total": this.total,
			// 		"level": this.level,
			// "province": this.province,
			// "city": this.city,
			// "county":this.county
			// 		}
            // ).then((res) => {
            //     // console.log(res)
			// 	this.tableData.length = 0
			// 	this.tableData.push(...res.data.data)
			// 	if (res.data.data.length != 0) this.total = res.data.data[0].total	
            //   } 
			// )		
	 },
	 
useHeight(){
				return new Promise((res) => {
					this.$nextTick(() => {
						let heightCount = 0
						let mainEl = document.querySelector('.table-container' + this.index)
						heightCount = mainEl?.clientHeight
						heightCount = heightCount - 36 - 45
						this.height= heightCount
		                res(heightCount)
					})
				})				
			},
			searh(){
				this.from = 0
				this.getData()
			}     
  },
   mounted() {
      this.getData();
	  this.useHeight()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	
	
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #ffffff;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
  :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
:deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}

</style>
