<template>
 <div>
	<div class="compare-box">
        <div class="compare-left">
            <div>
                <div style="color:#16F6C1;">授权量</div>
                <div class="left1">123451234</div>
            </div>
            <div>
                <div style="color:#F82020;">撤回量</div>
                <div class="left1">123451234</div>
            </div>
            <div>
                <div style="color:#F2E625;">驳回量</div>
                <div class="left1">123451234</div>
            </div>
        </div>
        <div class="compare-middle">
            <div>
                <div>
                    <img v-show="false" :src="require('/static/images/compare2.png')" alt="">
                    <img  :src="require('/static/images/compare3.png')" alt="">
                </div>
                <div>
                    <div>同比</div>
                    <div style="color:#16FF83;font-size:22px;">+11.69%</div>
                    <div style="color:#F82020;font-size:22px;" v-show="false">+11.69%</div>
                </div>
            </div>
            <div>
                 <div>
                    <img :src="require('/static/images/compare2.png')" alt="">
                    <img v-show="false" :src="require('/static/images/compare3.png')" alt="">
                </div>
                <div>
                    <div>同比</div>
                    <div style="color:#16FF83;font-size:22px;">+11.69%</div>
                    <div style="color:#F82020;font-size:22px;" v-show="false">+11.69%</div>
                </div>
            </div>
            <div>
                 <div>
                    <img :src="require('/static/images/compare2.png')" alt="">
                    <img v-show="false" :src="require('/static/images/compare3.png')" alt="">
                </div>
                <div>
                    <div>同比</div>
                    <div style="color:#16FF83;font-size:22px;">+11.69%</div>
                    <div style="color:#F82020;font-size:22px;" v-show="false">+11.69%</div>
                </div>
            </div>
        </div>
        <div class="compare-middle right">
            <div>
                <div>
                    <img :src="require('/static/images/compare4.png')" alt="">
                </div>
                <div>
                    <div>环比</div>
                    <div style="color:#16FF83;font-size:22px;">+11.69%</div>
                    <div style="color:#F82020;font-size:22px;" v-show="false">+11.69%</div>
                </div>
            </div>
            <div>
                 <div>
                    <img :src="require('/static/images/compare4.png')" alt="">
                </div>
                <div>
                    <div>环比</div>
                    <div style="color:#16FF83;font-size:22px;">+11.69%</div>
                    <div style="color:#F82020;font-size:22px;" v-show="false">+11.69%</div>
                </div>
            </div>
            <div>
                 <div>
                    <img :src="require('/static/images/compare4.png')" alt="">
                </div>
                <div>
                    <div>环比</div>
                    <div style="color:#16FF83;font-size:22px;" v-show="false">+11.69%</div>
                    <div style="color:#F82020;font-size:22px;" >+11.69%</div>
                </div>
            </div>
        </div>
    </div>
 </div>
</template>
<script>

export default {
  name: '',
  data(){
    return {

	 
	  tableData:[{

      }],

    
    }
  },
  props:{
		
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	
	 getData(){
		// console.log(this.come)
			// 	let url = ''
			// 	if (this.come == 1) {
			// 		url = 'dlbz'
			// 	} else if (this.come == 2) {
			// 		url = 'cmsb'
			// 	} 
			// 	this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/'+url,
			// 		{
			// 		"sbmc": '',
			// 		"applicant": this.applant,
			// 		"regId": '',
			// 		"intCls": '',
			// 		"from": this.from,
			// 		"size":this.size,
			// 		"total": this.total,
			// 		"level": this.level,
			// "province": this.province,
			// "city": this.city,
			// "county":this.county
			// 		}
            // ).then((res) => {
            //     // console.log(res)
			// 	this.tableData.length = 0
			// 	this.tableData.push(...res.data.data)
			// 	if (res.data.data.length != 0) this.total = res.data.data[0].total	
            //   } 
			// )		
	 }
			   
  },
   mounted() {
      this.getData();
	 
    }
}
</script>

<style scoped>
    .compare-box{
         margin-left: 40px;
         position: relative;
         height: 610px;
    }
    .compare-box>div{
        position: absolute;
    }
    .compare-left{
        width: 144px;
    }
    .compare-left>div{
         width: 144px;
         height: 144px;
        margin-bottom: 64px;
        position: relative;
         background: url('../../../static/images/compare1.png') 100% no-repeat;
    }
    .compare-left>div>div:nth-of-type(1){
          position: absolute;
            top:20px;
            left: 64px;
            font-size: 20px;
    }
    .compare-left>div>div:nth-of-type(2){
          position: absolute;
            top:50px;
            left: 64px;
            font-size: 20px;
    }
    .left1{
        color: #fff;
    }
    .compare-middle{
        left: 490px;
        width: 300px;
    }
    .compare-middle>div{
        margin-bottom: 88px;
        height: 124px;
    }
    .compare-middle>div>div{
        float: left;
    }
    .compare-middle>div>div:nth-of-type(2){
        margin-left: 28px;
    }
    .compare-middle>div>div:nth-of-type(2)>div:nth-of-type(1){
        color: #fff;
        margin:10px 0 40px;
    }
    .right{
        left: 1100px!important;
    }
</style>


