<template>
	<div class="main-container">
		<div class="tab-searh">
			<span>代理机构</span>
			<input type="text" v-model="applant"  placeholder="请输入代理机构名称">
			<span class="tab-searh-span" @click="searh()">搜索</span>
             <!-- <el-select v-model="value" placeholder="请选择" @change="chose" style="width:115px;height:36px;margin-left:140px">
                <el-option v-for="item in optionm"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"></el-option>
               
            </el-select> -->
			<div class="chosearea" >
				<span class="span1" :class="{active1:areaIndex==0}" @click="selectarea(0)">区域内代理机构</span>
				<span class="span2" :class="{active2:areaIndex==1}" @click="selectarea(1)">为区域企业代理过专利业务的代理机构</span>
			   </div>
		</div>
		<el-table :data="tableData" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
		    <el-table-column v-if="value == '1'" prop="agent" label="代理人" />
			<el-table-column v-if="value == '2'" prop="agency" label="代理机构"  />
			<el-table-column prop="famingShenqingCount" label="发明申请量" align="center"  />
		    <el-table-column prop="famingShouquanCount" label="发明有效量" align="center" />
			<el-table-column prop="shiyongCount" label="实用新型有效量" align="center" />
			<el-table-column prop="waiguanCount" label="外观有效量" align="center"  />
			<el-table-column v-if="value == '1'" prop="agency" label="所属代理机构"  />
		</el-table>
		    <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1000px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
	</div>
</template>
<script>
export default {
  name: '',
  data(){
    return {
		province:"",
      city:"",
		county:"",
		level:"",
	  applant:'',
	  from:0,
	  total:0,
	  size:20,
	  tableData:[],
	  height:550,
      optionm:[{
		id:'1',
        name:'代理人'
      },{
		id:"2",
        name:'代理机构'
      }],
      value:'2',
	  areaIndex:0
    }
  },
  props:{
		
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
	 selectarea(val){
		this.areaIndex = val
		this.applant = ""
		this.from =0;
		this.getData()
	 },
	//  chose(){
	// 	this.from =0;
	// 	this.applant = ""
	// 	this.getData()
	//  },
	 getData(){
				let url = ''
				if (this.value == "1") {
					url = 'getAgentCountData'
					this.$http.post(this.GLOBAL.new_url + '/patent/service/data/'+url,
					{
					 "year": '',
					"agent": this.applant,
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county
					}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)
				} else if (this.value == "2") {
				if (this.areaIndex == 0) {
					url = 'getAgencyLocalData'
					this.$http.post(this.GLOBAL.new_url + '/patent/service/data/'+url,
					{
					 "year": '',
					"agency": this.applant,
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county
					}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)
				} else if (this.areaIndex == 1) {
					url = 'getAgencyCountData'
					this.$http.post(this.GLOBAL.new_url + '/patent/service/data/'+url,
					{
					"year": '',
					"agency": this.applant,
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county
					}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)
				} 
				} 
			
						
	 },
	
useHeight(){
				return new Promise((res) => {
					this.$nextTick(() => {
						let heightCount = 0
						let mainEl = document.querySelector('.table-container' + this.index)
						heightCount = mainEl?.clientHeight
						heightCount = heightCount - 36 - 45
						this.height= heightCount
		                res(heightCount)
					})
				})				
			},
			searh(){
				this.from = 0
				this.getData()
			}     
  },
   mounted() {
	this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData();
	  this.useHeight()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	.tab-searh{
		color: #BAE0FF;
		margin: 0 0 25px 80px;
		
	}
	.tab-searh>input{
		width: 210px;
		height: 38px;
		outline: none;
		font-size: 16px;
		padding-left: 10px;
		background-color: transparent;
		border-radius: 4px;
		border: 1px solid #BAE0FF;
		margin: 0 25px;
		color: #fff;
	}
	.tab-searh-span{
		display: inline-block;
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		width: 76px;
		height: 32px;
		cursor: pointer;
		color: #ffffff;
		font-size: 18px;
		background: #264C8F;
	}
	input::-webkit-input-placeholder{
		color: #BAE0FF;
	}
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
:deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
.tab-searh :deep(.el-input__inner){
    padding-left: 14px!important;
    color: #FFFFFF!important;
	font-size: 16px;
}
.el-select-dropdown__item {
  font-size: 10px;
  color: #666666;
  font-weight: 500;
}
.chosearea{
	float: right;
	height: 40px;
}
.chosearea>span{
	display: inline-block;
	line-height: 40px;
	font-size: 18px;
	font-family: PingFang SC-Bold, PingFang SC;
	color: #FFFFFF;
	text-align: center;
	cursor: pointer;
}
.span1{
	width: 159px;
	background: url('../../../static/images/agent3.png') 100% no-repeat;
}
.span2{
	width: 338px;
	background: url('../../../static/images/agent2.png') 100% no-repeat;
}
.active1{
	background: url('../../../static/images/agent1.png') 100% no-repeat!important;
}
.active2{
	background: url('../../../static/images/agent4.png') 100% no-repeat!important;
}
:deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
</style>


