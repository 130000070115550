<template>
	<div class="main-container">
		<div class="right-year">
			<div class="right-year-lr">选择年份</div>
			<div class="block">
				<el-date-picker
					style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid #BAE0FF;"
					class="picker"
					:clearable="true"
					v-model="year"
					type="year"
					@change="yearChange"
					placeholder="选择年份">
					</el-date-picker>
			</div>
			<div class="chosetype">
			<span style="color:#BAE0FF;font-size:16px;">选择专利类型</span>
			<el-select v-model="value" placeholder="请选择" @change="chose" style="width:115px;height:36px;margin-left:40px">
					<el-option v-for="item in optionm"
							:key="item.name"
							:label="item.name"
							:value="item.id"></el-option>
				
				</el-select>
			</div>
		</div>
		<el-table :data="tableData" style="width: 1400px;overflow-y: auto;margin-left:80px;" :height="height + 'px'">
			<el-table-column prop="docNumber" label="专利号" align="center"  />
		    <el-table-column prop="changeBeforeApplicant" label="原权利人" align="center" />
			<el-table-column prop="changeAfterApplicant" label="转移后权利人" align="center" />
			<el-table-column prop="zymc" label="专利名称" align="center"  />
            <el-table-column prop="type" label="专利类型"  width="400" align="center">
				<template v-slot="scope">
					<span v-if="scope.row.type == 'faming'">发明授权</span>
					<span v-else-if="scope.row.type == 'shiyong'">实用新型</span>
					<span v-else>外观</span>
				</template>
			</el-table-column>
            <el-table-column prop="effectiveDate" label="登记生效日" align="center"  />
		</el-table>
		    <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1000px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
	</div>
</template>
<script>
export default {
  name: '',
  data(){
    return {
		province:"",
      city:"",
		county:"",
		level:"",
	
	  from:0,
	  total:0,
	  size:20,
	  tableData:[],
	  height:520,
	   year:'',
		value:'',
	  optionm:[{
		id:'',
        name:'全部类型'
      },{
		id:'faming',
        name:'发明授权'
      },{
		id:"shiyong",
        name:'实用新型'
      },{id:"waiguan",
        name:'外观设计'
      }],
    }
  },
   props:{
		come: {
				type: Number,
				default: () => {
					return 1
				},
			}
	  },
  components: {
   
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged(val){
		this.size = val
		this.from =0;
	    this.getData()
	},
     currentChanged(val){
		this.from = val - 1
		this.getData()
	 },
	 handleTableRow(row,column){
		this.$router.push({
			path:"/hingDetail",
			query:{
				company:row.sqrmc
			}
		})
	 },
	 chose(){
		this.from =0;
      this.getData()
	 },
	 yearChange(){
    this.from =0;
      this.getData()
    },
	 getData(){	
		let url = ''
				if (this.come == 1) {
					url = 'getZlqrzyOut'
				} else if (this.come == 2) {
					url = 'getZlqrzyIn'
				} 
				if (this.year == ""||this.year == null) {
					this.year = ''
				}else{
					this.year = new Date(this.year).getFullYear().toString();
				}
			this.$http.post(this.GLOBAL.new_url + '/patent/service/data/'+url,
					{
					"year": this.year,
					"from": this.from,
					"size":this.size,
					"total": this.total,
					"level": this.level,
					"province": this.province,
					"city": this.city,
					"county":this.county,
					"intCls":this.intCls,
					"town":this.town,
					"type":this.value
					}
            ).then((res) => {
                // console.log(res)
				this.tableData.length = 0
				this.tableData.push(...res.data.data)
				this.tableData.map((x)=>{
					x.effectiveDate = x.effectiveDate.split("T")[0]
				})
				if (res.data.data.length != 0) this.total = res.data.data[0].total	
              } 
			)		
	 },
	
useHeight(){
				return new Promise((res) => {
					this.$nextTick(() => {
						let heightCount = 0
						let mainEl = document.querySelector('.table-container' + this.index)
						heightCount = mainEl?.clientHeight
						heightCount = heightCount - 36 - 45
						this.height= heightCount
		                res(heightCount)
					})
				})				
			},    
  },
   mounted() {
	this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData();
	  this.useHeight()
    }
}
</script>

<style scoped>
	.main-container {
		height: 100%;
	}
	 :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
	/* cursor: pointer; */
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
.flex :deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
.tab-searh :deep(.el-input__inner){
    padding-left: 14px!important;
    color: #FFFFFF!important;
	font-size: 16px;
}
.el-select-dropdown__item {
  font-size: 10px;
  color: #666666;
  font-weight: 500;
}
:deep(.el-pagination) span{
  
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
 .right-year{
   height: 40px;
   margin-bottom: 50px;
   margin-left: 30px;
}
.right-year>div{
   float: left;
   margin-left: 50px;
}
.right-year-lr{
   line-height: 40px;
   font-size: 16px;
   font-family: PingFang SC-Medium, PingFang SC;
   color: #BAE0FF;
}
.chosetype :deep(.el-input__inner){
  border:1px solid #91CAFF;
    padding-left: 16px!important;
    color: #FFFFFF!important;
	font-size: 16px;
}
</style>


