<template>
<scale-box>
  <div class="patentData">
         <div class="patentData-title">
            <span class="to" @click="to">商标数据统计</span>
            <span class="to1" @click="to1">专利搜索引擎</span>
            <span class="to2" @click="to2">招商图谱</span>
         </div>
        <div class="patentData-midlle">
         <div class="middle-left">
             <div class="middle-left-but" :class="active">
                <div @click="change('one')" class="div1"> 
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'one'">
                   <span>年份申请量（授权量）</span>
                </div>
                <!-- <div @click="change('two')" class="div2">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'two'">
                   <span>年份有效量</span>
                </div> -->
                <!-- <div @click="change('three')" class="div3">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'three'">
                   <span>发明专利年费缴纳提醒</span>
                </div> -->
                <div @click="change('four')" class="div4">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'four'">
                   <span>专利代理机构</span>
                </div>
                <div @click="change('five')" class="div5">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'five'">
                   <span>各地区有效专利榜单</span>
                </div>
                 <!-- <div @click="change('six')" class="div6">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'six'">
                   <span>上月发明专利结案情况</span>
                </div> -->
                <div @click="change('eight')" class="div8">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'eight'">
                   <span style="display:inline-block;width:200px;">同时拥有商标和专利的企业数</span>
                </div>
                 <div @click="change('nine')" class="div9">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'nine'">
                   <span>高价值专利</span>
                </div>
                <div @click="change('ten')" class="div10">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'ten'">
                   <span>专利许可</span>
                </div>
                <div @click="change('ele')" class="div11">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'ele'">
                   <span>专利转移出</span>
                </div>
                <div @click="change('twe')" class="div12">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'twe'">
                   <span>专利转移进</span>
                </div>
                <div @click="change('thr')" class="div13">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'thr'">
                   <span>专利质押</span>
                </div>
                <div @click="change('seven')" class="div7">
                   <img :src="require('/static/data-arrow.png')" v-show="active == 'seven'">
                   <span>所有专利</span>
                </div>
               
            </div>
        </div>
        <div class="middle-right">
            <div class="right-div1">
               <div>
                  <div>{{this.patentVal1}}</div>
                  <img :src="require('/static/images/patent1.png')" alt="">
                  <div>万人有效发明专利持有量</div>
               </div>
               <div>
                  <div>{{this.patentVal2}}</div>
                  <img :src="require('/static/images/patent2.png')" alt="">
                  <div>万人发明专利申请量</div>
               </div>
               <div>
                  <div>{{this.patentVal3}}</div>
                  <img :src="require('/static/images/patent3.png')" alt="">
                  <div>发明申请量</div>
               </div>
               <div>
                  <div>{{this.patentVal4}}</div>
                  <img :src="require('/static/images/patent4.png')" alt="">
                  <div>发明授权量</div>
               </div>
               <!-- <div>
                  <div>{{}}</div>
                  <img :src="require('/static/images/patent5.png')" alt="">
                  <div>实用新型申请量</div>
               </div> -->
                <div>
                  <div>{{this.patentVal5}}</div>
                  <img :src="require('/static/images/patent6.png')" alt="">
                  <div>实用新型授权量</div>
               </div>
                <!-- <div>
                  <div>{{}}</div>
                  <img :src="require('/static/images/patent7.png')" alt="">
                  <div>外观申请量</div>
               </div> -->
                <div>
                  <div>{{this.patentVal6}}</div>
                  <img :src="require('/static/images/patent8.png')" alt="">
                  <div>外观授权量</div>
               </div>
            </div>
            <div class="right-bottom1" v-if="active == 'one'">
               <div class="right-year">
						<div class="right-year-lr">选择年份</div>
						<div class="block">
                  <el-date-picker
                     style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid #BAE0FF;"
                     class="picker"
                        :clearable="false"
                        v-model="year1"
                        type="year"
                        @change="yearChange1"
                        placeholder="选择年份">
                     </el-date-picker>
               </div>
					</div>
                <chart :option="data6.option" />
            </div>
            <!-- <div class="right-bottom1" v-if="active == 'two'">
               <div class="right-year">
						<div class="right-year-lr">选择年份</div>
						<div class="block">
                  <el-date-picker
                     style="width:180px;border-radius: 4px 4px 4px 4px;border: 1px solid #BAE0FF;"
                     class="picker"
                        :clearable="false"
                        v-model="year2"
                        type="year"
                        @change="yearChange2"
                        placeholder="选择年份">
                     </el-date-picker>
               </div>
					</div>
                <chart :option="data7.option" />
            </div> -->
            <div class="right-bottom1" v-if="active == 'three'">
              <PatentCost></PatentCost>
            </div>
           <div class="right-bottom1" v-if="active == 'four'">
              <PatentAgent></PatentAgent>
            </div>
            <div class="right-bottom1" v-if="active == 'five'">
              <PatentHot></PatentHot>
            </div>
            <div class="right-bottom1" v-if="active == 'six'">
              <PatentCompare></PatentCompare>
            </div>
            <div class="right-bottom1" v-if="active == 'seven'">
              <AllPatent></AllPatent>
            </div>
            <div class="right-bottom1" v-if="active == 'eight'">
              <TwoHave></TwoHave>
            </div>
            <div class="right-bottom1" v-if="active == 'nine'">
              <HingValue></HingValue>
            </div>
            <div class="right-bottom1" v-if="active == 'ten'">
              <PatentAgree></PatentAgree>
            </div>
            <div class="right-bottom1" v-if="active == 'ele'">
              <PatentTransfer1 :come="1"></PatentTransfer1>
            </div>
            <div class="right-bottom1" v-if="active == 'twe'">
              <PatentTransfer2 :come="2"></PatentTransfer2>
            </div>
            <div class="right-bottom1" v-if="active == 'thr'">
              <PatentYa></PatentYa>
            </div>
        </div>
      </div>
   </div>
   </scale-box>
</template>

<script>
import Chart from "../../components/chart/chart.vue";
import {
		getChartOption
	} from "../../components/data/chart_options";
   import scaleBox from "../../components/data/sclae-box";
import PatentCost from './patentCost.vue';
import PatentAgent from './patentAgent.vue';
import PatentHot from './patentHot.vue';
import PatentCompare from './patentCompare.vue';
import AllPatent from './allPatent.vue';
import TwoHave from './twoHave.vue';
import HingValue from './hingValue.vue';
import PatentAgree from './patentAgree.vue';
import PatentTransfer1 from './patentTransfer.vue';
import PatentTransfer2 from './patentTransfer.vue';
import PatentYa from './patentYa.vue';
import {patentData,patentyearApply,patentyearVal} from "../../api/api";
export default {
  name: 'patentData',
  data(){
    return {
      active:'one',
      year1:"2023",
      data6: {
         option: {},
      },
      data7: {
         option: {},
      },
      year2:"2023",
       province:"",
      city:"",
		county:"",
		level:"",
      patentVal1:'',
      patentVal2:'',
      patentVal3:'',
      patentVal4:'',
      patentVal5:'',
      patentVal6:''
    }
  },
  components: {
    scaleBox,
    Chart,
    PatentCost,
    PatentAgent,
    PatentHot,
    PatentCompare,
    AllPatent,
    TwoHave,
    HingValue,
    PatentAgree,
    PatentTransfer1,
    PatentTransfer2,
    PatentYa
  },
  watch:{
    
    
  },
  methods: {
   change(val){
    this.active = val
   },
   to(){
      this.$router.push({
         path:'/dataCount'
      })
   },
   to1(){
      this.$router.push({
         path:'/patentSearch'
      })
   },
   to2(){
      this.$router.push({
         path:'/guide'
      })
   },
    async getfulldata(){
      let res = await patentData({
         "year":'',
         "level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county,
         "town":this.town,
      })
      if(res.data.code === 200){
         	// console.log(res)
            this.patentVal1 = res.data.data.perValid.toFixed(2);
            this.patentVal2 = res.data.data.perCount.toFixed(2);
            this.patentVal3 = res.data.data.famingShenqingCount;
            this.patentVal4 = res.data.data.famingShouquanCount
            this.patentVal5 = res.data.data.shiyongCount;
            this.patentVal6 = res.data.data.waiguanCount;
           
      }
    },
     async getallData2(){
      let res = await patentyearApply({
         "year": new Date(this.year1).getFullYear(),
			"level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county
      })
      if(res.data.code === 200){
         // console.log(res)
      var rightBottom = res.data.data;
         let res2 = await patentyearVal({
         "year": new Date(this.year1).getFullYear(),
			"level": this.level,
			"province": this.province,
			"city": this.city,
			"county":this.county
      })
      if(res2.data.code === 200){
         // console.log(res)
      var rightBottom2 = res2.data.data;
      this.data6.option = getChartOption("data6", {
							xData: rightBottom.map((i) => i.year).reverse(),
							yData: [
								rightBottom.map((i) => i.count),
                        rightBottom2.map((i) => i.count),
							],
						});
						this.data6.option.series[0].data = rightBottom.map(
							(i) => i.count
						);
                  this.data6.option.series[0].data = this.data6.option.series[0].data.reverse()
                  this.data6.option.series[1].data = rightBottom2.map(
							(i) => i.count
						);
                  this.data6.option.series[1].data = this.data6.option.series[1].data.reverse()
       
      }
        
      }
    },
   //  async getallData3(){
   //    let res = await patentyearVal({
   //       "year": new Date(this.year2).getFullYear(),
	// 		"level": this.level,
	// 		"province": this.province,
	// 		"city": this.city,
	// 		"county":this.county
   //    })
   //    if(res.data.code === 200){
   //       // console.log(res)
   //    var rightBottom = res.data.data;
   //      this.data7.option = getChartOption("data7", {
	// 						xData: rightBottom.map((i) => i.year).reverse(),
	// 						yData: [
	// 							rightBottom.map((i) => i.count)
	// 						],
	// 					});
	// 					this.data7.option.series[0].data = rightBottom.map(
	// 						(i) => i.count
	// 					);
   //                this.data7.option.series[0].data = this.data7.option.series[0].data.reverse()
   //    }
   //  },  
    
   
    yearChange1(){
     this.getallData2()
    },
   //  yearChange2(){
   //    this.getallData3()
   //  },
  },
   mounted() {
       this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getfulldata()
      this.getallData2()
      // this.getallData3()
      if (this.$route.query.from == "0") {
            this.change('eight')
         }
         if (this.$route.query.from == "1") {
            this.change('nine')
         }
    },
    beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#0e2140;')
        },
}
</script>

<style scoped>
* {
		margin: 0;
		padding: 0;
	}
.patentData{
  width: 1920px;
  height: 1028px;
  background: url('../../../static/data.png') 100% no-repeat;
  background-size: cover;
  padding: 24px 0 28px;
  margin: 0 auto ;
}
.patentData-title{
    margin-left: 100px;
    font-size: 20px;
   font-family: DIN-Bold, DIN;
    color: #FFFFFF;
    margin-bottom: 46px;
}
.patentData-title>span{
    color: #18f8ff;
}
.patentData-midlle{
    margin: 0 auto;
    height: 900px;
}
.patentData-midlle>div{
   float: left;
}
.middle-left{
    /* width: 259px;
    height: 800px; */
    width: 304px;
    height: 900px;
    background: url('../../../static/data-left2.png') 100% no-repeat;
    background-size: cover;
    margin-left: 64px;
    padding-top: 55px;
    /* filter: drop-shadow(5px 0px 10px rgb(9, 187, 189)); */
}
.middle-left-but>div{
    position: relative;
    height: 22px;
    margin-bottom: 45px;
    cursor: pointer;
}
.middle-left>div>div>img{
  top:5px;
  position: absolute;
    left: 35px;
}
.middle-left>div>div>span{
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    color: #FFFFFF;
    position: absolute;
    left: 48px;
}
.one .div1>span,.two .div2>span,.three .div3>span,.four .div4>span,.five .div5>span,.six .div6>span,.seven .div7>span,.eight .div8>span,.nine .div9>span
,.ten .div10>span,.ele .div11>span,.twe .div12>span,.thr .div13>span{
    color: #18F8FF;;
}
.middle-right{
   /* margin-left: 45px; */
}
.right-div1{
   height: 165px;
   margin-bottom: 74px;
}
.right-div1>div{
   float: left;
   text-align: center;
   /* margin-left: 55px; */
   margin-left: 110px;
}

.right-div1>div>div:nth-of-type(1){
   font-size: 24px;
   font-family: DIN-Bold, DIN;
   color: #BAE0FF;
}
.right-div1>div>div:nth-of-type(2){
   font-size: 16px;
font-family: PingFang SC-Medium, PingFang SC;
color: #FFFFFF;
}
:deep(.el-input__inner){
   background-color: transparent;
   color: #FFFFFF;
   padding-left: 110px;
   border: none;
}
.right-year{
   height: 40px;
}
.right-year>div{
   float: left;
   margin-left: 50px;
}
.right-year-lr{
   line-height: 40px;
   font-size: 16px;
   font-family: PingFang SC-Medium, PingFang SC;
   color: #BAE0FF;
}
.to{
   display: inline-block;
   width: 170px;
   height: 40px;
   background: #002C8C;
   box-shadow: inset 0px 5px 15px 1px #1890FF;
   border: 1px solid #1890FF;
   text-align: center;
   line-height: 40px;
   cursor: pointer;
   color: #FFFFFF!important;
   margin-left: 1150px;
}
.to1{
   display: inline-block;
   width: 170px;
   height: 40px;
   background: #002C8C;
   box-shadow: inset 0px 5px 15px 1px #1890FF;
   border: 1px solid #1890FF;
   text-align: center;
   line-height: 40px;
   cursor: pointer;
   color: #FFFFFF!important;
   margin-left: 40px;
}
.to2{
   display: inline-block;
   width: 170px;
   height: 40px;
   background: #002C8C;
   box-shadow: inset 0px 5px 15px 1px #1890FF;
   border: 1px solid #1890FF;
   text-align: center;
   line-height: 40px;
   cursor: pointer;
   color: #FFFFFF!important;
   margin-left: 40px;
}
</style>



